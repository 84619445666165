"use client";

import type { FC } from "react";
import Image from "next/image";
import type { ImageProps as NextImageProps } from "next/image";

export type TmdbImageProps = Omit<NextImageProps, "unoptimized" | "loader"> & {
  src: string;
  defaultLoader?: boolean;
};

const baseUrl = "https://image.tmdb.org/t/p/";

const TmdbImage: FC<TmdbImageProps> = ({ alt, src, ...props }) => {
  const fullSrc = `${baseUrl}original${src}`;

  const unoptimize = src.endsWith(".svg");

  return <Image unoptimized={unoptimize} alt={alt} src={fullSrc} {...props} />;
};

export default TmdbImage;
